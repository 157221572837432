import React, { useState, useEffect } from 'react';
import { Button, Form, Col, Row } from 'react-bootstrap';

interface CoursesFormProps {
    hideShowForm: (action: string) => void;
    action: string;
    row: any;
    getData: () => void;
}

export const CoursesForm: React.FC<CoursesFormProps> = ({ hideShowForm, action, row, getData }) => {
    const [courseData, setCourseData] = useState(row || {});

    useEffect(() => {
        setCourseData(row);
    }, [row]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setCourseData({ ...courseData, [name]: value });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // Call API to save course data
        // After saving, refresh data
        getData();
        hideShowForm('');
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col md={2}>
                    <Form.Group controlId="formTitle">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            type="text"
                            name="Title"
                            value={courseData.Title || ''}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group controlId="formStatus">
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                            type="text"
                            name="Status"
                            value={courseData.Status || ''}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group controlId="formPrice">
                        <Form.Label>Price</Form.Label>
                        <Form.Control
                            type="number"
                            name="Price"
                            value={courseData.Price || ''}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group controlId="formDuration">
                        <Form.Label>Duration</Form.Label>
                        <Form.Control
                            type="number"
                            name="Duration"
                            value={courseData.Duration || ''}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
            {/* Add more form fields as necessary */}
            <Button variant="primary" type="submit">
                Save
            </Button>
            <Button variant="secondary" onClick={() => hideShowForm('')}>
                Cancel
            </Button>
        </Form>
    );
};

export default CoursesForm;

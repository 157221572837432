import React from "react";
import "styles/app.scss";
import "styles/global.scss";
import { Provider } from "react-redux";
import store from "redux/store";
import Pages from "pages";
import AuthInitializer from "components/auth/AuthInitializer";

function App(): JSX.Element {
    return (
        <Provider store={store}>
            <AuthInitializer>
                <div className="App">
                    <Pages />
                </div>
            </AuthInitializer>
        </Provider>
    );
}

export default App;

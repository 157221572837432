import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from 'redux/store';

interface AuthenticatedRouteProps {
    element: JSX.Element;
    adminOnly?: boolean;
}

const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({ element, adminOnly = false }) => {
    const isAuthenticated = useAppSelector((state) => state.authToken.isAuthenticated);
    const user = useAppSelector((state) => state.authToken.userData);

    console.log("AuthenticatedRoute: User is authenticated?", isAuthenticated);
    console.log("AuthenticatedRoute:", user);

    if (!isAuthenticated) {
        return <Navigate to="/" replace />;
    }

    if (adminOnly && user?.account !== 'admin') {
        return <Navigate to="/dashboard" replace />;
    }

    return element;
};

export default AuthenticatedRoute;

import React, { useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Layout from 'template';
import Prompts from './Prompts';
import { useAppSelector } from "redux/store";
import { useNavigate } from 'react-router';

const Dashboard: React.FC = () => {
    const isAuthenticated = useAppSelector((state) => state.authToken.isAuthenticated); // Utiliser "authToken" au lieu de "auth"
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login', { replace: true }); // Empêcher l'utilisateur de revenir en arrière après la redirection
        }
    }, [isAuthenticated, navigate]);

    return (
        <Layout>
            <Container fluid className="px-4">
                {/* En-tête de la page */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Prompts</h1>
                </div>

                {/* Contenu des prompts */}
                <Row className="mt-4">
                    <Col xl={12}>
                        <Prompts />
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};

export default Dashboard;

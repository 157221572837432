import { combineReducers } from "redux";

import template from "redux/slices/template";
import authToken from "redux/slices/auth";
import users from "redux/slices/users";
import courses from "redux/slices/courses"; // Import the courses reducer

const rootReducer = combineReducers({
    template,
    authToken,
    users,
    courses // Add the courses reducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'redux/store';
import { setJWTToken, removeJWTToken } from 'redux/slices/auth';

const AuthInitializer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log("AuthInitializer: Checking localStorage for JWT token...");

        const jwtToken = localStorage.getItem('jwtToken');
        const expiryDate = localStorage.getItem('expire');
        const userData = localStorage.getItem('userData');

        console.log("AuthInitializer: Retrieved from localStorage:", { jwtToken, expiryDate, userData });

        if (jwtToken && expiryDate && new Date().getTime() < Number(expiryDate)) {
            console.log("AuthInitializer: Token is valid, setting it in the Redux store...");
            dispatch(setJWTToken({
                jwtToken,
                expiryDate: Number(expiryDate),
                userData: userData ? JSON.parse(userData) : null,
                isAuthenticated: true,
                errorMessage: ''
            }));
        } else {
            console.log("AuthInitializer: No valid token found, clearing store and localStorage...");
            dispatch(removeJWTToken());
        }

        setLoading(false);
    }, [dispatch]);

    if (loading) {
        return <div>Loading...</div>; // Vous pouvez rendre un spinner ou un écran de chargement ici
    }

    return <>{children}</>;
};

export default AuthInitializer;

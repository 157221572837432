import React from 'react';
import { Card, Button } from 'react-bootstrap';

const CoursesCard = ({ course, handleEnroll }) => {
    return (
        <Card>
            <Card.Body>
                <Card.Title className="text-dark"><span style={{fontSize: '1.4em'}}>{course.Title}</span></Card.Title>
                <Card.Text className="text-dark"><strong>Durée:</strong> {course.Duration} heures</Card.Text>
                <Card.Text className="text-dark"><strong>Prix:</strong> {course.Price} CHF</Card.Text>
                <Button variant="primary" className="btn-primary-3emelieu" onClick={handleEnroll}>S'inscrire</Button>
            </Card.Body>
        </Card>
    );
};

export default CoursesCard;

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://195.15.235.220:8084';

export const getCourses = async (params) => {
    try {
        // Construct the URL with query parameters
        const url = new URL(`${API_BASE_URL}/api/v1/courses`);
        url.search = new URLSearchParams({
            page: params.page,
            pageSize: params.pageSize,
            searchKey: params.searchKey,
            filters: params.filters
        }).toString();

        // Make the fetch request
        const response = await fetch(url.toString(), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        // Check if the response is OK
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Parse the JSON response
        const data = await response.json();
        console.log('API call response:', data);
        return data;
    } catch (error) {
        console.error('Error during API call:', error);
        throw error;
    }
};

// src/components/admin/index.tsx

import React from "react";
import Layout from "../../template";
import PromptForm from "./PromptForm";
import AdminPrompts from "./PromptForm"; // Import the PromptForm component

const Admin: React.FC = () => {
    return (
        <Layout>
            <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0">Administration</h1>
                </div>
                <div className="d-flex flex-column min-vh-100">
                    <AdminPrompts /> {/* Include the PromptForm component */}
                </div>
            </div>
        </Layout>
    );
};

export default Admin;

import React from "react";
import { useNavigate } from "react-router";
import { Button, Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { activateTopMenu, sidebarToggle } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { removeJWTToken } from "redux/slices/auth";

const Topbar: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const handleActiveDropdown = (menuName: string) => {
        dispatch(activateTopMenu(menuName));
    }

    const isTopActiveMenu = useSelector((state: RootState) => state.template.topActiveMenu);
    const userProfile = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : undefined;

    const handleLogout = () => {
        dispatch(removeJWTToken()); // Mise à jour de l'état Redux pour refléter la déconnexion
        navigate('/', { replace: true }); // Rediriger vers la page de connexion
    }

    return (
        <Nav className="navbar navbar-expand navbar-dark topbar mb-4 static-top shadow">
            <button id="sidebarToggleTop" className="btn btn-link text-dark d-md-none rounded-circle mr-3"
                    onClick={() => dispatch(sidebarToggle())}>
                <i className="fa fa-bars"></i>
            </button>
            <ul className="navbar-nav ml-auto">


                <li className={isTopActiveMenu === "Profile" ? "nav-item dropdown no-arrow show" : "nav-item dropdown no-arrow"}
                    onClick={() => handleActiveDropdown("Profile")}>
                    <a className="nav-link dropdown-toggle" id="userDropdown" role="button"
                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span
                            className="mr-2 d-none d-lg-inline small"><>{userProfile ? Object.values(userProfile)[1] : 'NA'}</></span>
                        <img className="img-profile rounded-circle"
                             src="https://picsum.photos/50/50"/>
                    </a>
                    <div
                        className={isTopActiveMenu === "Profile" ? "dropdown-menu dropdown-menu-right shadow animated--grow-in show" : "dropdown-menu dropdown-menu-right shadow animated--grow-in"}
                        aria-labelledby="userDropdown">

                        <Button variant="link" className="dropdown-item" onClick={() => navigate('/profile')}>
                            <i className="fas fa-user fa-sm fa-fw mr-2"></i>
                            <>{userProfile ? Object.values(userProfile)[1] : 'NA'}</>
                        </Button>

                        <div className="dropdown-divider"></div>

                        <Button variant="link" className="dropdown-item" onClick={handleLogout}>
                            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2"></i>
                            Logout
                        </Button>

                    </div>
                </li>
            </ul>
        </Nav>
    );
};

export default Topbar;

// services/authService.ts
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://195.15.235.220:8084';
export const tokenAPICALL = async (user: string, pass: string) => {
    try {
        console.log("Attempting login with:", { user, pass });

        console.log(`${API_BASE_URL}/api/v1/token`)

        const response = await fetch(`${API_BASE_URL}/api/v1/token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8'
            },
            body: JSON.stringify({
                email: user,
                password: pass
            })
        });

        if (response.headers.get('content-type')?.includes('application/json')) {
            const data = await response.json();
            console.log("API Response:", data);

            if (response.ok && data.token) {
                return {
                    jwtToken: data.token,
                    expiryDate: new Date().getTime() + 3600000, // Exemple d'expiration à 1h
                    userData: data.user
                };
            } else {
                console.error("Login failed, no token received.");
                return null;
            }
        } else {
            console.error("Login error: Response is not JSON");
            return null;
        }
    } catch (error) {
        console.error("Login error:", error.message || error);
        return null;
    }
};

// Vérifier si l'email existe déjà
export const checkEmailExists = async (email: string) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/v1/users/check-email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email })
        });

        if (response.ok) {
            const data = await response.json();
            return data.exists; // Assuming your backend returns { exists: true } if email exists
        } else {
            throw new Error('Failed to check email');
        }
    } catch (error) {
        console.error('Failed to check email:', error);
        throw error;
    }
};

// Updated registerUser function to accept language as an argument
export const registerUser = async (email: string, password: string, language: string) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/v1/users/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email, password, language }) // Include language in the request body
        });

        if (response.ok) {
            return await response.json();
        } else {
            throw new Error('Registration failed');
        }
    } catch (error) {
        console.error('Failed to register user:', error);
        throw error;
    }
};

import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Form, InputGroup, Button, ListGroup, Container } from 'react-bootstrap';
import { FunnelSimple, XCircle, MagnifyingGlass, Pencil, Trash, PlusCircle } from 'phosphor-react';
import PromptModal from './PromptModal';
import DeleteConfirmModal from './DeleteConfirmModal';

type Prompt = {
    id: number;
    title: string;
    prompt: string;
    category: string;
    level: number;
};

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://195.15.235.220:8084';

const AdminPrompts: React.FC = () => {
    const [prompts, setPrompts] = useState<Prompt[]>([]);
    const [filteredPrompts, setFilteredPrompts] = useState<Prompt[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [category, setCategory] = useState('');
    const [level, setLevel] = useState<number | null>(null);
    const [showFilters, setShowFilters] = useState(false);
    const [showPromptModal, setShowPromptModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedPrompt, setSelectedPrompt] = useState<Prompt | null>(null);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        // Fetch prompts from the backend
        const fetchPrompts = async () => {
            const response = await fetch(`${API_BASE_URL}/api/v1/prompts`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
                },
            });
            const data = await response.json();
            setPrompts(data);
            setFilteredPrompts(data);
        };

        fetchPrompts();
    }, []);

    useEffect(() => {
        filterPrompts();
    }, [searchTerm, category, level]);

    const filterPrompts = () => {
        let results = prompts;
        if (searchTerm) {
            results = results.filter((prompt) =>
                prompt.prompt.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }
        if (category) {
            results = results.filter((prompt) =>
                prompt.category.toLowerCase().includes(category.toLowerCase())
            );
        }
        if (level !== null) {
            results = results.filter((prompt) => prompt.level === level);
        }
        setFilteredPrompts(results);
    };

    const handleSavePrompt = async (title: string, content: string, category: string, level: number) => {
        if (isEditing && selectedPrompt) {
            // Update existing prompt
            try {
                await fetch(`${API_BASE_URL}/api/v1/prompts/${selectedPrompt.id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
                    },
                    body: JSON.stringify({ title, prompt: content, category, level }),
                });

                const updatedPrompts = prompts.map((p) =>
                    p.id === selectedPrompt.id ? { ...p, title, prompt: content, category, level } : p
                );
                setPrompts(updatedPrompts);
                setFilteredPrompts(updatedPrompts);
            } catch (error) {
                console.error('Failed to update prompt:', error);
            }
        } else {
            // Create new prompt
            try {
                const response = await fetch(`${API_BASE_URL}/api/v1/prompts`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
                    },
                    body: JSON.stringify({ title, prompt: content, category, level }),
                });

                const newPrompt = await response.json();
                setPrompts([...prompts, newPrompt]);
                setFilteredPrompts([...filteredPrompts, newPrompt]);
            } catch (error) {
                console.error('Failed to create prompt:', error);
            }
        }
        setShowPromptModal(false);
    };

    const handleDeletePrompt = async () => {
        if (selectedPrompt) {
            try {
                await fetch(`${API_BASE_URL}/api/v1/prompts/${selectedPrompt.id}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
                    },
                });

                const remainingPrompts = prompts.filter((p) => p.id !== selectedPrompt.id);
                setPrompts(remainingPrompts);
                setFilteredPrompts(remainingPrompts);
            } catch (error) {
                console.error('Failed to delete prompt:', error);
            }
        }
        setShowDeleteModal(false);
    };

    const handleOpenEditModal = (prompt: Prompt) => {
        setSelectedPrompt(prompt);
        setIsEditing(true);
        setShowPromptModal(true);
    };

    const handleOpenCreateModal = () => {
        setSelectedPrompt(null);
        setIsEditing(false);
        setShowPromptModal(true);
    };

    const handleOpenDeleteModal = (prompt: Prompt) => {
        setSelectedPrompt(prompt);
        setShowDeleteModal(true);
    };

    return (
        <Container>
            <Card className="shadow-lg mb-4 text-white">
                <Card.Body>
                    <Row className="mb-4">
                        <Col>
                            <InputGroup className="input-group">
                                <Form.Control
                                    type="text"
                                    placeholder="Rechercher des prompts..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <Button variant="primary"><MagnifyingGlass size={24} /></Button>
                                <Button variant="secondary" onClick={() => setShowFilters(!showFilters)}>
                                    {showFilters ? <XCircle size={24} /> : <FunnelSimple size={24} />}
                                </Button>
                            </InputGroup>
                        </Col>
                    </Row>
                    {showFilters && (
                        <Row className="mb-4">
                            <Col md={6}>
                                <Form.Control
                                    as="select"
                                    value={category}
                                    onChange={(e) => setCategory(e.target.value)}
                                >
                                    <option value="">Toutes les catégories</option>
                                    <option value="Conjugaison">Conjugaison</option>
                                    <option value="Vocabulaire">Vocabulaire</option>
                                    <option value="Expression écrite">Expression écrite</option>
                                    <option value="Compréhension orale">Compréhension orale</option>
                                    <option value="Grammaire">Grammaire</option>
                                </Form.Control>
                            </Col>
                            <Col md={6}>
                                <Form.Control
                                    as="select"
                                    value={level !== null ? level : ''}
                                    onChange={(e) => setLevel(e.target.value === '' ? null : parseInt(e.target.value))}
                                >
                                    <option value="">Tous les niveaux</option>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                </Form.Control>
                            </Col>
                        </Row>
                    )}
                    <div style={{margin: 16}}>
                    <Button variant="primary" onClick={handleOpenCreateModal}>
                        <PlusCircle size={24} className="mr-2" /> Create New Prompt
                    </Button>
                    </div>
                    <ListGroup variant="flush">
                        {filteredPrompts.map((prompt) => (
                            <ListGroup.Item key={prompt.id} className="d-flex justify-content-between align-items-center list-group-item">
                                <div>
                                    <div className="prompt-text">{prompt.title}</div>
                                    <div className="prompt-text" style={{fontWeight: '200', fontSize: '1.0em', border: '1px solid rgba(0,0,0,0.1)', padding: 6, margin: 10, borderRadius: 10}}>{prompt.prompt}</div>
                                    <div className="text-muted category-text">Catégorie: {prompt.category}</div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="text-muted level-text" style={{whiteSpace: 'nowrap'}}>
                                        Niveau: {prompt.level}
                                    </div>
                                    <Button variant="secondary" onClick={() => handleOpenEditModal(prompt)} className="ml-3">
                                        <Pencil size={24} />
                                    </Button>
                                    <Button variant="danger" onClick={() => handleOpenDeleteModal(prompt)} className="ml-2">
                                        <Trash size={24} />
                                    </Button>
                                </div>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Card.Body>
            </Card>

            {/* Prompt Modal */}
            <PromptModal
                show={showPromptModal}
                onHide={() => setShowPromptModal(false)}
                onSave={handleSavePrompt}
                initialData={isEditing && selectedPrompt ? { title: selectedPrompt.title, content: selectedPrompt.prompt, category: selectedPrompt.category, level: selectedPrompt.level } : undefined}
                isEdit={isEditing}
            />

            {/* Delete Confirmation Modal */}
            <DeleteConfirmModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                onDelete={handleDeletePrompt}
            />
        </Container>
    );
};

export default AdminPrompts;

import React, {useState, useEffect} from "react";
import {Alert, Button, Row, Col, Form, Modal} from "react-bootstrap";
import {useSelector, useDispatch} from "react-redux";
import {setCoursesList, setCoursesMessage} from "../../redux/actions";
import {RootState} from "../../redux/reducers";
import {getCourses} from "../../services/coursesService";
import Layout from "../../template";
import {Constant} from "../../template/Constant";
import CoursesForm from "./CoursesForm";
import CoursesCard from "./CoursesCard";
import {Funnel, MagnifyingGlass, ArrowLeft, ArrowRight} from "phosphor-react";
import CourseDetailModal from "./CourseDetailModal"; // Import the new modal

const Cours: React.FC = () => {
    const dispatch = useDispatch();
    const rData = useSelector((state: RootState) => state.courses);
    const [row, setRow] = useState<any>(undefined);
    const [action, setAction] = useState('');
    const [searchKey, setSearchKey] = useState<string>('');
    const [page, setPage] = useState(1);
    const [pageSize] = useState(12);
    const [showFilters, setShowFilters] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState(null); // State for selected course

    const [price, setPrice] = useState<number | undefined>(undefined);
    const [duration, setDuration] = useState<number | undefined>(undefined);
    const [status, setStatus] = useState<string>('');

    const [filterPrice, setFilterPrice] = useState<number | undefined>(undefined);
    const [filterDuration, setFilterDuration] = useState<number | undefined>(undefined);
    const [filterStatus, setFilterStatus] = useState<string>('');

    const getData = async (page = 1, pageSize = 12, searchKey = '', price?: number, duration?: number, status?: string) => {
        try {
            console.log("Fetching data with filters:", {page, pageSize, searchKey, price, duration, status});
            const response = await getCourses({page, pageSize, searchKey, price, duration, status});

            if (response && response.records) {
                const totalCount = response.totalCount || response.records.length;
                dispatch(setCoursesList({
                    pageNo: page,
                    pageSize: pageSize,
                    list: response.records,
                    totalCount,
                    searchKey
                }));
            } else {
                dispatch(setCoursesMessage("No Record Found"));
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des données des cours:', error);
            dispatch(setCoursesMessage("Erreur lors de la récupération des données des cours. Veuillez réessayer plus tard."));
        }
    };

    useEffect(() => {
        getData(page, pageSize, searchKey, filterPrice, filterDuration, filterStatus);
    }, [page, searchKey, filterPrice, filterDuration, filterStatus]);

    useEffect(() => {
        setPage(1); // Reset to the first page when searchKey changes
    }, [searchKey]);

    const handleApplyFilters = () => {
        console.log("Applying filters:", {price, duration, status});
        setFilterPrice(price);
        setFilterDuration(duration);
        setFilterStatus(status);
        setPage(1); // Reset to the first page when filters are applied
    }

    const totalPages = Math.ceil(rData.totalCount / pageSize);

    const handleShowModal = (course) => {
        setSelectedCourse(course);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedCourse(null);
    };

    return (
        <Layout>
            <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-white">Cours</h1>
                </div>
                <div className="d-flex flex-column min-vh-100">
                    {rData.message &&
                        <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade show">
                            {rData.message}
                            <Button type="button" className="close" data-dismiss="alert" aria-label="Close"
                                    onClick={() => dispatch(setCoursesMessage(''))}>
                                <span aria-hidden="true">&times;</span>
                            </Button>
                        </Alert>
                    }
                    <div className="d-flex justify-content-between mb-4">
                        <Button variant="outline-primary" onClick={() => setShowFilters(!showFilters)}>
                            <Funnel size={20}/> Filtres
                        </Button>
                        <Form className="form-inline ml-2">
                            <Form.Control type="text" value={searchKey} onChange={(e) => setSearchKey(e.target.value)}
                                          placeholder="Rechercher des cours" className="mr-sm-2"/>
                            <Button variant="outline-primary" onClick={() => setPage(1)}>
                                <MagnifyingGlass size={20}/>
                            </Button>
                        </Form>
                    </div>
                    {showFilters && (
                        <Row className="mb-4">
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label>Prix (CHF)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={price || ''}
                                        onChange={(e) => setPrice(e.target.value ? Number(e.target.value) : undefined)}
                                        placeholder="Entrer le prix maximum"
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label>Durée (heures)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={duration || ''}
                                        onChange={(e) => setDuration(e.target.value ? Number(e.target.value) : undefined)}
                                        placeholder="Entrer la durée maximum"
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label>Status</Form.Label>
                                    <Form.Control as="select" value={status}
                                                  onChange={(e) => setStatus(e.target.value)}>
                                        <option value="">Tous</option>
                                        <option value="active">Actif</option>
                                        <option value="paused">En pause</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <div style={{
                                marginTop: 16,
                                marginBottom: 32,
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <Button variant="primary" onClick={handleApplyFilters} style={{fontSize: '1.2em'}}>Appliquer les filtres</Button>
                            </div>
                        </Row>
                    )}
                    {action ?
                        <CoursesForm hideShowForm={setAction} action={action} row={row} getData={getData}/> :
                        <Row>
                            {rData.list.map(course => (
                                <Col md={3} key={course.id} className="mb-4">
                                    <CoursesCard course={course} handleEnroll={() => handleShowModal(course)}/>
                                </Col>
                            ))}
                        </Row>
                    }
                    <div className="pagination d-flex justify-content-center mt-4">
                        <Button variant="outline-primary" disabled={page === 1} onClick={() => setPage(page - 1)}>
                            <ArrowLeft size={20}/>
                        </Button>
                        <span className="mx-3">{`Page ${page} de ${totalPages}`}</span>
                        <Button variant="outline-primary" disabled={page === totalPages}
                                onClick={() => setPage(page + 1)}>
                            <ArrowRight size={20}/>
                        </Button>
                    </div>
                </div>
            </div>
            {selectedCourse && (
                <CourseDetailModal show={showModal} handleClose={handleCloseModal} courseData={selectedCourse}/>
            )}
        </Layout>
    );
};

export default Cours;

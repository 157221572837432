import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserProfile {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    language: string;
    profilePicture?: string;
    account: string; // This line includes the account/role information
}

interface IAuthToken {
    isAuthenticated: boolean;
    jwtToken: string;
    expiryDate: number | undefined;
    errorMessage?: string;
    userData?: UserProfile;
}

const initialState: IAuthToken = {
    jwtToken: '',
    expiryDate: undefined,
    errorMessage: '',
    isAuthenticated: false,
    userData: undefined,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setJWTToken: (state, action: PayloadAction<IAuthToken>) => {
            state.jwtToken = action.payload.jwtToken;
            state.expiryDate = action.payload.expiryDate;
            state.userData = action.payload.userData;
            state.isAuthenticated = true;
            state.errorMessage = '';

            if (state.jwtToken) {
                localStorage.setItem('jwtToken', state.jwtToken);
            }
            if (state.expiryDate) {
                localStorage.setItem('expire', state.expiryDate.toString());
            }
            if (state.userData) {
                localStorage.setItem('userData', JSON.stringify(state.userData));
            }
        },
        removeJWTToken: (state) => {
            localStorage.clear();
            state.jwtToken = '';
            state.expiryDate = undefined;
            state.isAuthenticated = false;
            state.userData = undefined;
            state.errorMessage = '';
        },
        setError: (state, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
        },
        updateUserProfile: (state, action: PayloadAction<Partial<UserProfile>>) => {
            if (state.userData) {
                state.userData = { ...state.userData, ...action.payload };
                localStorage.setItem('userData', JSON.stringify(state.userData));
            }
        },
    },
});

export const { setJWTToken, removeJWTToken, setError, updateUserProfile } = authSlice.actions;

export default authSlice.reducer;

import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector as rawUseSelector, TypedUseSelectorHook } from "react-redux";
import rootReducer from "./reducers";

// Configuration du store Redux
const store = configureStore({ reducer: rootReducer });

// Définition des types pour le dispatch et l'état
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

// Exportation des hooks personnalisés pour le dispatch et le sélecteur
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = rawUseSelector;

export default store;

import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Form, InputGroup, Button, ListGroup, Container } from 'react-bootstrap';
import { FunnelSimple, XCircle, MagnifyingGlass, Chat } from 'phosphor-react';
import './Prompts.css';
import ChatModal from './ChatModal'; // Import ChatModal component

type Prompt = {
    id: number;
    title: string;
    prompt: string;
    category: string;
    level: number;
};

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://195.15.235.220:8084';

const Prompts: React.FC = () => {
    const [prompts, setPrompts] = useState<Prompt[]>([]);
    const [filteredPrompts, setFilteredPrompts] = useState<Prompt[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [category, setCategory] = useState('');
    const [level, setLevel] = useState<number | null>(null);
    const [showFilters, setShowFilters] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedPrompt, setSelectedPrompt] = useState<Prompt | null>(null);

    useEffect(() => {
        // Fetch prompts from the backend
        const fetchPrompts = async () => {
            const response = await fetch(`${API_BASE_URL}/api/v1/prompts`);
            const data = await response.json();
            setPrompts(data);
            setFilteredPrompts(data);
        };

        fetchPrompts();
    }, []);

    useEffect(() => {
        filterPrompts();
    }, [searchTerm, category, level]);

    const filterPrompts = () => {
        let results = prompts;
        if (searchTerm) {
            results = results.filter((prompt) =>
                prompt.prompt.toLowerCase().includes(searchTerm.toLowerCase()) ||
                prompt.title.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }
        if (category) {
            results = results.filter((prompt) =>
                prompt.category.toLowerCase().includes(category.toLowerCase())
            );
        }
        if (level !== null) {
            results = results.filter((prompt) => prompt.level === level);
        }
        setFilteredPrompts(results);
    };

    const handleOpenModal = (prompt: Prompt) => {
        setSelectedPrompt(prompt);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedPrompt(null);
    };

    return (
        <Container>
            <Card className="shadow-lg mb-4 text-white prompts">
                <Card.Body>
                    {/* Search and Filter Section */}
                    <Row className="mb-4">
                        <Col xs={12} className="d-flex">
                            <InputGroup className="input-group w-100">
                                <Form.Control
                                    type="text"
                                    placeholder="Rechercher des prompts..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className="form-control-sm"
                                />
                                <Button variant="primary" className="btn-sm">
                                    <MagnifyingGlass size={20} />
                                </Button>
                                <Button variant="secondary" className="btn-sm ml-2" onClick={() => setShowFilters(!showFilters)}>
                                    {showFilters ? <XCircle size={20} /> : <FunnelSimple size={20} />}
                                </Button>
                            </InputGroup>
                        </Col>
                    </Row>

                    {/* Filter Dropdowns */}
                    {showFilters && (
                        <Row className="mb-4">
                            <Col xs={12} sm={6} className="mb-2 mb-sm-0">
                                <Form.Control
                                    as="select"
                                    value={category}
                                    onChange={(e) => setCategory(e.target.value)}
                                >
                                    <option value="">Toutes les catégories</option>
                                    <option value="Conjugaison">Conjugaison</option>
                                    <option value="Vocabulaire">Vocabulaire</option>
                                    <option value="Expression écrite">Expression écrite</option>
                                    <option value="Compréhension orale">Compréhension orale</option>
                                    <option value="Grammaire">Grammaire</option>
                                </Form.Control>
                            </Col>
                            <Col xs={12} sm={6}>
                                <Form.Control
                                    as="select"
                                    value={level !== null ? level : ''}
                                    onChange={(e) => setLevel(e.target.value === '' ? null : parseInt(e.target.value))}
                                >
                                    <option value="">Tous les niveaux</option>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                </Form.Control>
                            </Col>
                        </Row>
                    )}

                    {/* List of Prompts */}
                    <ListGroup variant="flush">
                        {filteredPrompts.map((prompt) => (
                            <ListGroup.Item key={prompt.id} className="list-group-item menu-prompts">
                                <div>
                                    <div className="prompt-title" style={{ fontSize: '1.2em' }}>{prompt.title}</div>
                                    <div className="text-muted category-text">Catégorie: {prompt.category}</div>
                                </div>
                                <div className="menu-prompts-bt-chat">
                                    <div className="text-muted level-text">Niveau: {prompt.level}</div>
                                    <Button variant="primary" onClick={() => handleOpenModal(prompt)} className="ml-3 btn-sm">
                                        <Chat size={20} />
                                    </Button>
                                </div>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Card.Body>
            </Card>

            {/* Modal for Chat */}
            {selectedPrompt && (
                <ChatModal
                    show={showModal}
                    onHide={handleCloseModal}
                    prompt={selectedPrompt.prompt}
                    title={selectedPrompt.title}
                />
            )}
        </Container>
    );
};

export default Prompts;

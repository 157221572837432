import { APIService } from "services";

export const getUsers = async (pageNo: number, pageSize: number, search: string) => {
    let res;
    if (search.length === 0) {
        res = await getAllUsers(pageNo, pageSize);
    } else {
        try {
            res = await searchUsers(search, pageNo, pageSize);
        } catch (err) {
            return { records: [], totalCount: 0 };
        }
    }

    if (res && res.data) {
        return res.data;
    } else {
        return { records: [], totalCount: 0 };
    }
}

export const addUsers = async (data: any) => {
    return await APIService.api('/users', {
        method: 'POST',
        body: JSON.stringify(data),
    });
}

export const updateUsers = async (id: string, data: any) => {
    return await APIService.api(`/users/${id}`, {
        method: 'PATCH',
        body: JSON.stringify(data),
    });
}

export const getAllUsers = async (pageNo: number, pageSize: number) => {
    return await APIService.api(`/users/?pageNo=${pageNo}&pageSize=${pageSize}`, {
        method: 'GET',
    });
}

export const getOneUsers = async (id: string) => {
    return await APIService.api(`/users/${id}`, {
        method: 'GET',
    });
}

export const searchUsers = async (searchKey: string, pageNo: number, pageSize: number) => {
    return await APIService.api(`/users/search/${searchKey}/?pageNo=${pageNo}&pageSize=${pageSize}`, {
        method: 'GET',
    });
}

export const deleteUsers = async (id: string) => {
    return await APIService.api(`/users/${id}`, {
        method: 'DELETE',
    });
}

import React, { useState } from "react";
import { Button, Form, Container, Row, Col, Card, Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { updateUserProfile } from "redux/slices/auth";
import { useNavigate } from "react-router-dom";
import Layout from "../../template";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://195.15.235.220:8084';

const Profile: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const userProfile = useSelector((state: RootState) => state.authToken.userData);

    const [firstName, setFirstName] = useState(userProfile?.firstName || '');
    const [lastName, setLastName] = useState(userProfile?.lastName || '');
    const [language, setLanguage] = useState(userProfile?.language || 'en');
    const [profilePicture, setProfilePicture] = useState(userProfile?.profilePicture || '');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);

    const handleUpdateProfile = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);
        setError(null);
        setSuccess(null);

        const updatedProfile = {
            firstName: firstName,
            lastName: lastName,
            profilePicture: profilePicture,
            language: language,
            updated_at: new Date().toISOString(),
        };

        console.log('Updated profile data:', updatedProfile);

        try {
            const token = localStorage.getItem('jwtToken');
            if (!token) {
                alert('Please log in again.');
                navigate('/login');
                return;
            }

            const response = await fetch(`${API_BASE_URL}/api/v1/users/${userProfile.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(updatedProfile),
            });

            const data = await response.json();
            console.log('Response from server:', data);

            if (response.ok) {
                setSuccess('Profile updated successfully!');
                // Dispatch the update to Redux store if necessary
                dispatch(updateUserProfile(data.data));
            } else {
                setError('Failed to update profile: ' + (data.message || 'Unknown error'));
            }
        } catch (error) {
            console.error('Error updating profile:', error);
            setError('Error updating profile: ' + error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Layout>
            <Container fluid className="px-4">
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Profile</h1>
                </div>
                <Row className="mt-4">
                    <Col md={4}>
                        <Card className="shadow-lg mb-4">
                            <Card.Img variant="top" src={userProfile?.profilePicture || 'void.png'} alt="Profile Picture" />
                            <Card.Body>
                                <Card.Title>{userProfile?.firstName} {userProfile?.lastName}</Card.Title>
                                <Card.Text>
                                    <strong>Email:</strong> {userProfile?.email}<br/>
                                    <strong>Language:</strong> {userProfile?.language === "en" ? "English" : "Français"}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={8}>
                        <Card className="shadow-lg mb-4">
                            <Card.Body>
                                <h4 className="mb-3">Update Your Profile</h4>
                                <Form onSubmit={handleUpdateProfile}>
                                    <Form.Group controlId="formFirstName">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="First Name"
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="formLastName">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Last Name"
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="formProfilePicture">
                                        <Form.Label>Profile Picture URL</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Profile Picture URL"
                                            value={profilePicture}
                                            onChange={(e) => setProfilePicture(e.target.value)}
                                        />
                                        {profilePicture && (
                                            <div className="mt-3">
                                                <img
                                                    src={profilePicture}
                                                    alt="Profile Preview"
                                                    style={{ width: 100, height: 100, borderRadius: '50%' }}
                                                />
                                            </div>
                                        )}
                                    </Form.Group>

                                    <Form.Group controlId="formLanguage">
                                        <Form.Label>Preferred Language</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={language}
                                            onChange={(e) => setLanguage(e.target.value)}
                                        >
                                            <option value="fr">Français</option>
                                            <option value="en">English</option>
                                        </Form.Control>
                                    </Form.Group>

                                    {error && <Alert variant="danger">{error}</Alert>}
                                    {success && <Alert variant="success">{success}</Alert>}

                                    <Button variant="primary" type="submit" disabled={isLoading} className="mt-3">
                                        {isLoading ? 'Saving...' : 'Save Changes'}
                                    </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};

export default Profile;

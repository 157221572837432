import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

interface PromptModalProps {
    show: boolean;
    onHide: () => void;
    onSave: (title: string, content: string, category: string, level: number) => void;
    initialData?: { title: string; content: string; category: string; level: number };
    isEdit?: boolean;
}

const PromptModal: React.FC<PromptModalProps> = ({ show, onHide, onSave, initialData, isEdit }) => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [category, setCategory] = useState('');
    const [level, setLevel] = useState(1);

    useEffect(() => {
        if (initialData) {
            setTitle(initialData.title);
            setContent(initialData.content);
            setCategory(initialData.category);
            setLevel(initialData.level);
        }
    }, [initialData]);

    const handleSubmit = () => {
        onSave(title, content, category, level);
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? 'Edit Prompt' : 'Create New Prompt'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formTitle">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formContent" className="mt-3">
                        <Form.Label>Prompt</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formCategory" className="mt-3">
                        <Form.Label>Category</Form.Label>
                        <Form.Control
                            as="select"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            required
                        >
                            <option value="Conjugaison">Conjugaison</option>
                            <option value="Vocabulaire">Vocabulaire</option>
                            <option value="Expression écrite">Expression écrite</option>
                            <option value="Compréhension orale">Compréhension orale</option>
                            <option value="Grammaire">Grammaire</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formLevel" className="mt-3">
                        <Form.Label>Level</Form.Label>
                        <Form.Control
                            as="select"
                            value={level}
                            onChange={(e) => setLevel(parseInt(e.target.value))}
                            required
                        >
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    {isEdit ? 'Update' : 'Create'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PromptModal;

import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { CheckCircle, Info, Calendar, Question, Article, Cube, ListChecks, Target, Briefcase, Wrench, Users, Image, GraduationCap, Laptop, Coin } from "phosphor-react";
import dayjs from "dayjs";
import 'dayjs/locale/fr'; // Importez la locale française
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const CourseDetailModal = ({ show, handleClose, courseData }) => {
    // Parse Axes if it's a string
    let axesArray = [];
    try {
        axesArray = typeof courseData.Axes === 'string' ? JSON.parse(courseData.Axes) : courseData.Axes || [];
    } catch (error) {
        console.error('Error parsing Axes:', error);
    }

    const axesElements = axesArray.map((value, index) => {
        if (value === 'se') {
            return (
                <span key={index} className="badge rounded-pill m-1" style={{ backgroundColor: '#ff5e5e', color: '#000' }}>Sensei</span>
            );
        } else if (value === 'ds') {
            return (
                <span key={index} className="badge rounded-pill m-1" style={{ backgroundColor: '#5e8fff', color: '#000' }}>dS happiness</span>
            );
        } else {
            return null;
        }
    });

    // Parse Agenda if it's a string
    let agendaArray = [];
    try {
        agendaArray = typeof courseData.Agenda === 'string' ? JSON.parse(courseData.Agenda) : courseData.Agenda || [];
    } catch (error) {
        console.error('Error parsing Agenda:', error);
    }

    const agendaElements = agendaArray.map((course, index) => (
        <div key={index} className="course mb-3">
            <div className="accordion-header-small accordion-button" style={{ backgroundColor: '#333', color: '#fff' }}>
                <h5>
                    <span style={{ marginRight: 10 }}><CheckCircle size={24} /></span> {course.courseName}
                </h5>
                <div className="accordion-progress">
                    <span style={{ color: '#fff', fontSize: '0.9em' }}>
                        {course.Confirmed < 100 ? (
                            <span style={{ whiteSpace: 'nowrap', float: 'right' }}>Cours garanti à :</span>
                        ) : (
                            course.Confirmed === 100 ? (
                                <span style={{ whiteSpace: 'nowrap', color: '#45c4a0', float: 'right' }}>Cours garanti !</span>
                            ) : (
                                <span style={{ whiteSpace: 'nowrap', float: 'right' }}>Cours complet</span>
                            )
                        )}
                    </span>
                    <div className="progress-container mt-2" style={{ backgroundColor: '#444', borderRadius: '5px', overflow: 'hidden' }}>
                        <div className="progress-bar" style={{ width: `${course.Confirmed}%`, backgroundColor: '#007bff' }}>
                            <div style={{ fontSize: '0.8em', color: '#fff', textAlign: 'center' }}>{course.Confirmed}%</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="accordion-collapse collapse show mt-2" style={{ backgroundColor: '#222', color: '#ddd' }}>
                <div className="row row-cols-1 row-cols-md-2 g-4">
                    {course.sessions.map((session, sessionIndex) => (
                        <div key={sessionIndex} className="col">
                            <div className="card" style={{ backgroundColor: '#333', color: '#ddd', margin: '10px 0px', borderRadius: '5px' }}>
                                <div className="card-body">
                                    <h6 className="card-title" style={{ textAlign: 'center' }}>Journée {sessionIndex + 1}</h6>
                                    <div className="card-text">
                                        <p className="session-date">Date: <strong>{session.date}</strong></p>
                                        <hr style={{ margin: 0, borderColor: '#444' }} />
                                        <p className="session-hours">Heure: <strong>{session.hours.join(', ')}</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    ));


    let toolsElements = null;
    if (courseData.Tools && courseData.Tools.trim() !== "") {
        try {
            const tools = courseData.Tools ? JSON.parse(courseData.Tools).obj : [];
            toolsElements = tools.map((tool, index) => (
                <div key={index} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <img src={tool.img} alt={tool.description} style={{
                        maxHeight: '92px',
                        padding: 7,
                        borderRadius: '450px',
                        border: '4px solid rgba(255,255,255,0.1)'
                    }} />
                    <h5 style={{ fontSize: '0.8em', fontWeight: 100, textAlign: 'center' }}>{tool.description}</h5>
                </div>
            ));
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
    }

    let subjectsElements = null;
    if (courseData.Subjects) {
        const subjects = courseData.Subjects ? JSON.parse(courseData.Subjects) : [];
        subjectsElements = subjects.map((subject, index) => (
            <>
                <div
                    className={`subject accordion-button ${index === 0 ? '' : 'collapsed'}`}
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapseSubject${index}`}
                    aria-expanded={index === 0 ? 'true' : 'false'}
                    aria-controls={`collapseSubject${index}`}
                >
                    <h4 className="accordion-header-small" id={`subjectHeading${index}`}>
                        <span style={{ marginRight: 10, marginTop: '-16px' }}><CheckCircle size={32} /></span>
                        {subject.titre}
                    </h4>
                </div>
                <div
                    id={`collapseSubject${index}`}
                    className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                    aria-labelledby={`subjectHeading${index}`}
                >
                    <div className="list-group-item">
                        <table className="table table-striped table-sm">
                            <tbody style={{ border: 'none' }}>
                            {subject.description.map((item, itemIndex) => (
                                <tr key={itemIndex}>
                                    <td style={{ verticalAlign: 'center', color: '#fff', fontWeight: 100 }}
                                        dangerouslySetInnerHTML={{ __html: item }}></td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        ));
    }

    let objectiveElements = '';
    if (courseData.Objective) {
        const objectives = courseData.Objective ? JSON.parse(courseData.Objective).obj : [];
        objectiveElements = objectives.map((objective, index) => (
            <li className="list-group-item d-flex align-items-center" key={index}
                style={{ textAlign: 'left', margin: 10, fontWeight: '100' }}>
                {objective.description}
            </li>
        ));
    }

    let toolsAdmit = null;
    if (courseData.Type) {
        if (courseData.Type === 'Certificate') {
            toolsAdmit = (
                <div>
                    <p><CheckCircle size={32} weight="thin" style={{ color: '#00ff8c', marginRight: 10 }} />Être majeur</p>
                    <p><CheckCircle size={32} weight="thin" style={{ color: '#00ff8c', marginRight: 10 }} />Être capable de discernement</p>
                    <p><CheckCircle size={32} weight="thin" style={{ color: '#00ff8c', marginRight: 10 }} />Maturité gymnasiale, diplôme de l’ECG, CFC, ou un titre ou compétences jugés équivalents lors de l’entretien personnel.</p>
                </div>
            );
        } else if (courseData.Type === 'Diploma') {
            toolsAdmit = (
                <div>
                    <p><CheckCircle size={32} weight="thin" style={{ color: '#00ff8c', marginRight: 10 }} />Être majeur</p>
                    <p><CheckCircle size={32} weight="thin" style={{ color: '#00ff8c', marginRight: 10 }} />Être capable de discernement</p>
                    <p><CheckCircle size={32} weight="thin" style={{ color: '#00ff8c', marginRight: 10 }} />Maturité gymnasiale, diplôme de l’ECG, CFC, ou un titre ou compétences jugés équivalents lors de l’entretien personnel.</p>
                    <p><CheckCircle size={32} weight="thin" style={{ color: '#00ff8c', marginRight: 10 }} /><span className="underline-2 style-3 fuchsia">Prérequis pour entrée en 2ème année :</span> avoir effectué la première année dans la branche étudiée, et obtenu avec succès le <strong>Certificat</strong> de la branche ou équivalence selon l’entretien personnel avec la direction.</p>
                </div>
            );
        } else if (courseData.Type === 'Bachelor') {
            toolsAdmit = (
                <div>
                    <p><CheckCircle size={32} weight="thin" style={{ color: '#00ff8c', marginRight: 10 }} />Être majeur</p>
                    <p><CheckCircle size={32} weight="thin" style={{ color: '#00ff8c', marginRight: 10 }} />Être capable de discernement</p>
                    <p><CheckCircle size={32} weight="thin" style={{ color: '#00ff8c', marginRight: 10 }} />Maturité gymnasiale, diplôme de l’ECG, CFC, ou un titre ou compétences jugés équivalents lors de l’entretien personnel.</p>
                    <p><CheckCircle size={32} weight="thin" style={{ color: '#00ff8c', marginRight: 10 }} /><span className="underline-2 style-3 fuchsia">Prérequis pour entrée en 3ème année :</span> avoir effectué la première et la deuxième année dans la branche étudiée, et obtenu avec succès le <strong>Diplôme</strong> de la branche ou équivalence selon l’entretien personnel avec la direction.</p>
                </div>
            );
        }
    }

    let careerElements = '';
    if (courseData.Career) {
        const careers = courseData.Career ? JSON.parse(courseData.Career).obj : [];
        careerElements = careers.map((career, index) => (
            <li className="list-group-item d-flex align-items-center" key={index}
                style={{ textAlign: 'left', margin: 10, fontWeight: '100' }}>
                {career.description}
            </li>
        ));
    }

    return (
        <Modal show={show} onHide={handleClose} size="xl" dialogClassName="modal-fullscreen" backdrop="static">
            <Modal.Header closeButton style={{ borderBottom: '1px solid #444', backgroundColor: '#222', color: '#fff' }}>
                <Modal.Title>{courseData.Title}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: '#222', color: '#ddd' }}>
                <div className="mb-4">
                    <h5><Question size={32} weight="thin" style={{ color: '#5257ff', marginRight: 10 }} /> Description:</h5>
                    <p>{courseData.SmallDescription}</p>
                </div>
                <div className="mb-4">
                    <h5><Calendar size={32} weight="thin" style={{ color: '#5257ff', marginRight: 10 }} /> Agenda:</h5>
                    {agendaElements}
                </div>
                <div className="mb-4">
                    <h5><Cube size={32} weight="thin" style={{ color: '#5257ff', marginRight: 10 }} /> Axes:</h5>
                    {axesElements}
                </div>
                <div className="mb-4">
                    <h5><Info size={32} weight="thin" style={{ color: '#5257ff', marginRight: 10 }} /> Admission:</h5>
                    {toolsAdmit}
                </div>
                {courseData.Subjects && (
                    <div className="mb-4">
                        <h5><ListChecks size={32} weight="thin" style={{ color: '#5257ff', marginRight: 10 }} /> Matières:</h5>
                        <ul className="list-group">
                            {subjectsElements}
                        </ul>
                    </div>
                )}
                {courseData.Objective && (
                    <div className="mb-4">
                        <h5><Target size={32} weight="thin" style={{ color: '#5257ff', marginRight: 10 }} /> Objectifs:</h5>
                        <ul className="list-group">
                            {objectiveElements}
                        </ul>
                    </div>
                )}
                {courseData.Tools && (
                    <div className="mb-4">
                        <h5><Wrench size={32} weight="thin" style={{ color: '#5257ff', marginRight: 10 }} /> Logiciels & Outils:</h5>
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            {toolsElements}
                        </div>
                    </div>
                )}
                {courseData.Career && (
                    <div className="mb-4">
                        <h5><Briefcase size={32} weight="thin" style={{ color: '#5257ff', marginRight: 10 }} /> Carrière & Perspectives:</h5>
                        <ul className="list-group">
                            {careerElements}
                        </ul>
                    </div>
                )}
                {courseData.Matos && (
                    <div className="bg-3emelieu-2 p-4 rounded">
                        <h5 className="h4-slug d-flex align-items-center">
                            <Laptop size={32} weight="thin" style={{ color: '#5257ff', marginRight: '10px' }} /> Inclus avec votre formation
                        </h5>
                        <div className="matos">
                            <div>
                                {JSON.parse(courseData.Matos).image && (
                                    <img src={JSON.parse(courseData.Matos).image}
                                         alt={JSON.parse(courseData.Matos).h1}
                                         style={{ maxWidth: '320px', borderRadius: '8px', margin: 12 }} />
                                )}
                            </div>
                            <div>
                                <h1 className="h1">{JSON.parse(courseData.Matos).h1}</h1>
                                <hr style={{ margin: 4 }} />
                                <h3 className="h5"
                                    dangerouslySetInnerHTML={{ __html: JSON.parse(courseData.Matos).h2 }}></h3>
                                <hr style={{ margin: 4 }} />
                                <div
                                    dangerouslySetInnerHTML={{ __html: JSON.parse(courseData.Matos).description }}></div>
                            </div>
                        </div>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer style={{ borderTop: '1px solid #444', backgroundColor: '#222' }}>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CourseDetailModal;

import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { registerUser, checkEmailExists } from "services/authService";
import { Alert, Button, Form, Card, Spinner, Container, Row, Col, Modal } from "react-bootstrap";
import Layout from "template";
import { CheckCircle } from 'phosphor-react'; // Import the Phosphor icon

const Register: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [showSuccessModal, setShowSuccessModal] = useState(false); // State for success modal
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            confirmPassword: '',
            language: 'fr',  // Default language
        },
        validationSchema: yup.object({
            email: yup.string().email('Invalid email address').required('Email is required'),
            password: yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
            confirmPassword: yup.string()
                .oneOf([yup.ref('password')], 'Passwords must match')
                .required('Confirm Password is required'),
            language: yup.string().required('Language is required'), // Language validation
        }),
        onSubmit: async (values) => {
            setLoading(true);
            setErrorMessage(null);

            try {
                const emailExists = await checkEmailExists(values.email);
                if (emailExists) {
                    setErrorMessage("Email already exists. Please use a different email.");
                    setLoading(false);
                    return;
                }

                const result = await registerUser(values.email, values.password, values.language);
                if (result) {
                    setShowSuccessModal(true); // Show success modal
                } else {
                    setErrorMessage("Registration failed. Please try again.");
                }
            } catch (error) {
                setErrorMessage("An error occurred during registration. Please try again.");
            } finally {
                setLoading(false);
            }
        }
    });

    const handleCloseSuccessModal = () => {
        setShowSuccessModal(false);
        navigate('/'); // Redirect to home or login page after closing the modal
    };

    return (
        <Container>
            <Row className="justify-content-center" style={{ marginTop: 32 }}>
                <Col md={8} lg={6}>
                    <Card>
                        <Card.Body>
                            <Card.Title className="text-center">Register</Card.Title>
                            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                            <Form onSubmit={formik.handleSubmit}>
                                <Form.Group>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        isInvalid={!!formik.touched.email && !!formik.errors.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.email}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="password"
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        isInvalid={!!formik.touched.password && !!formik.errors.password}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.password}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="confirmPassword"
                                        value={formik.values.confirmPassword}
                                        onChange={formik.handleChange}
                                        isInvalid={!!formik.touched.confirmPassword && !!formik.errors.confirmPassword}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.confirmPassword}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Language</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="language"
                                        value={formik.values.language}
                                        onChange={formik.handleChange}
                                    >
                                        <option value="fr">Français</option>                {/* French */}
                                        <option value="en">English</option>                 {/* English */}
                                        <option value="es">Español</option>                 {/* Spanish */}
                                        <option value="de">Deutsch</option>                 {/* German */}
                                        <option value="it">Italiano</option>                {/* Italian */}
                                        <option value="pt">Português</option>               {/* Portuguese */}
                                        <option value="ru">Русский</option>                {/* Russian */}
                                        <option value="nl">Nederlands</option>              {/* Dutch */}
                                        <option value="sv">Svenska</option>                 {/* Swedish */}
                                        <option value="da">Dansk</option>                   {/* Danish */}
                                        <option value="fi">Suomi</option>                   {/* Finnish */}
                                        <option value="no">Norsk</option>                   {/* Norwegian */}
                                        <option value="pl">Polski</option>                  {/* Polish */}
                                        <option value="el">Ελληνικά</option>               {/* Greek */}
                                        <option value="hu">Magyar</option>                  {/* Hungarian */}
                                        <option value="cs">Čeština</option>                 {/* Czech */}
                                        <option value="sk">Slovenčina</option>              {/* Slovak */}
                                        <option value="bg">Български</option>              {/* Bulgarian */}
                                        <option value="hr">Hrvatski</option>                {/* Croatian */}
                                        <option value="ro">Română</option>                  {/* Romanian */}
                                        <option value="sr">Српски</option>                 {/* Serbian */}
                                        <option value="uk">Українська</option>             {/* Ukrainian */}
                                        <option value="lt">Lietuvių</option>                {/* Lithuanian */}
                                        <option value="lv">Latviešu</option>                {/* Latvian */}
                                        <option value="et">Eesti</option>                   {/* Estonian */}
                                        <option value="sl">Slovenščina</option>            {/* Slovenian */}
                                        <option value="ga">Gaeilge</option>                 {/* Irish */}
                                    </Form.Control>
                                </Form.Group>

                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 32 }}>
                                    <Button type="submit" variant="primary" disabled={loading}>
                                        {loading ? <Spinner animation="border" size="sm" /> : "Register"}
                                    </Button>
                                </div>
                            </Form>
                            <div className="mt-3 text-center">
                                <Link to="/login">Already have an account? Login</Link>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Success Modal */}
            <Modal
                show={showSuccessModal}
                onHide={handleCloseSuccessModal}
                centered
                className="text-center"
            >
                <Modal.Header closeButton className="border-0">
                    <Modal.Title className="w-100">
                        <CheckCircle size={48} color="#28a745" weight="fill" style={{ marginBottom: '10px' }} />
                        <div style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                            Registration Successful
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ fontSize: '1.1rem', marginBottom: '20px' }}>
                        Your registration was successful! A confirmation email has been sent to your email address.
                        Please check your inbox and click the link to verify your email address.
                    </p>
                </Modal.Body>
                <Modal.Footer className="border-0 justify-content-center">
                    <Button variant="primary" onClick={handleCloseSuccessModal} style={{ padding: '10px 20px', fontSize: '1rem' }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default Register;

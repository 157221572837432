import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from "./login";
import Register from "./register";
import AuthenticatedRoute from "components/auth/AuthenticatedRoute";
import { NotFound } from "./404";
import { Profile, Dashboard, Events, Cours, Messages } from "components";
import Admin from "components/admin"; // Admin component

const Pages: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/dashboard" element={<AuthenticatedRoute element={<Dashboard />} />} />
                <Route path="/cours" element={<AuthenticatedRoute element={<Cours />} />} />
                <Route path="/events" element={<AuthenticatedRoute element={<Events />} />} />
                <Route path="/messages" element={<AuthenticatedRoute element={<Messages />} />} />
                <Route path="/profile" element={<AuthenticatedRoute element={<Profile />} />} />
                <Route path="/admin" element={<AuthenticatedRoute element={<Admin />} adminOnly />} /> {/* Restricted to admin */}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Pages;

import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form, InputGroup, Spinner, Alert } from 'react-bootstrap';
import { Microphone, Play, Stop } from 'phosphor-react';
import { jsPDF } from 'jspdf';
import './ChatModal.css';

interface ChatModalProps {
    show: boolean;
    onHide: () => void;
    prompt: string;
    title: string;
}

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://195.15.235.220:8084';

const ChatModal: React.FC<ChatModalProps> = ({ show, onHide, prompt, title }) => {
    const [hasStarted, setHasStarted] = useState(false);
    const [chatResponse, setChatResponse] = useState<string | null>(null);
    const [question, setQuestion] = useState('');
    const [conversationHistory, setConversationHistory] = useState<string[]>([]);
    const [isListening, setIsListening] = useState(false);
    const [loading, setLoading] = useState(false);
    const [audioUrl, setAudioUrl] = useState<string | null>(null);
    const [highlightedWordIndex, setHighlightedWordIndex] = useState<number | null>(null);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const conversationEndRef = useRef<HTMLDivElement | null>(null);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const audioChunksRef = useRef<Blob[]>([]);

    const promptSend = `SYSTEM: poursuis cette conversation en respectant toujours bien l’instruction de départ qui est:  ${prompt}, très court, de 4 à 8 mots. Une seul question. toujours en francais. `;

    useEffect(() => {
        if (conversationEndRef.current) {
            conversationEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [conversationHistory]);

    const fetchInitialChatGPTResponse = async () => {
        try {
            setLoading(true);
            const response = await fetch(`${API_BASE_URL}/api/v1/chatgpt`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
                },
                body: JSON.stringify({
                    model: 'gpt-4',
                    question: promptSend,
                }),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const data = await response.json();
            if (data.response) {
                setChatResponse(data.response);
                setConversationHistory([` ${data.response}`]);
            } else {
                setConversationHistory(['Erreur lors de l\'initialisation de la conversation.']);
            }

            if (data.audioResponsePath) {
                setAudioUrl(`${API_BASE_URL}/${data.audioResponsePath}`);
            }

            setLoading(false);
        } catch (error) {
            setChatResponse("Une erreur s'est produite lors de l'initialisation de l'exercice. Veuillez réessayer.");
            setLoading(false);
        }
    };

    useEffect(() => {
        if (show && hasStarted) {
            fetchInitialChatGPTResponse();
        }
    }, [show, hasStarted]);

    const handleChatGPTResponse = async (question: string) => {
        try {
            // Inclure le prompt initial à chaque fois avant l'historique de la conversation
            const fullConversation = [`${promptSend}`, ...conversationHistory, `Utilisateur: ${question}`].join('\n');

            const response = await fetch(`${API_BASE_URL}/api/v1/chatgpt`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
                },
                body: JSON.stringify({
                    model: 'gpt-4',
                    question: fullConversation,
                }),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const data = await response.json();

            if (data.response) {
                setChatResponse(data.response);
                // Mettre à jour l'historique avec la nouvelle réponse
                setConversationHistory([...conversationHistory, `Utilisateur: ${question}`, `${data.response}`]);
            }

            if (data.audioResponsePath) {
                setAudioUrl(`${API_BASE_URL}/${data.audioResponsePath}`);
            }
        } catch (error) {
            setChatResponse("Une erreur s'est produite. Veuillez réessayer.");
        }
    };

    const startRecording = async () => {
        try {
            setIsListening(true);
            setLoading(true);

            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const mediaRecorder = new MediaRecorder(stream);
            mediaRecorderRef.current = mediaRecorder;

            audioChunksRef.current = [];

            mediaRecorder.ondataavailable = (event) => {
                if (event.data && event.data.size > 0) {
                    audioChunksRef.current.push(event.data);
                }
            };

            mediaRecorder.onerror = () => {
                alert('Une erreur est survenue lors de l\'enregistrement.');
                setLoading(false);
            };

            mediaRecorder.onstop = async () => {
                setIsListening(false);

                if (audioChunksRef.current.length === 0) {
                    alert('Aucun audio n\'a été enregistré. Veuillez réessayer.');
                    setLoading(false);
                    return;
                }

                const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });

                const formData = new FormData();
                formData.append('audioFile', audioBlob);

                try {
                    const response = await fetch(`${API_BASE_URL}/api/v1/transcribe`, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
                        },
                        body: formData,
                    });

                    const data = await response.json();
                    const transcribedText = data.transcript;

                    if (transcribedText) {
                        setQuestion(transcribedText);
                        await handleChatGPTResponse(transcribedText);
                    }
                } catch (error) {
                    alert('La transcription a échoué. Veuillez réessayer.');
                } finally {
                    setLoading(false);
                    setQuestion('');
                }
            };

            mediaRecorder.start();
        } catch (error) {
            alert('Impossible d\'accéder au microphone.');
            setIsListening(false);
            setLoading(false);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            setIsListening(false);
        }
    };

    const speakText = () => {
        if (audioRef.current) {
            audioRef.current.play();
        }
    };

    const resetSpeaking = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
    };

    // Fonction manquante : démarre l'exercice
    const handleStartExercise = () => {
        setHasStarted(true);
    };

    // Fonction manquante : met à jour l'index des mots surlignés lors de la lecture audio
    const handleTimeUpdate = () => {
        if (audioRef.current && chatResponse) {
            const words = chatResponse.split(' ');
            const currentTime = audioRef.current.currentTime;
            const duration = audioRef.current.duration;
            const wordIndex = Math.floor((currentTime / duration) * words.length);
            setHighlightedWordIndex(wordIndex);
        }
    };

    const handleSendQuestion = async () => {
        if (question.trim()) {
            setLoading(true);
            await handleChatGPTResponse(question);
            setLoading(false);
            setQuestion('');
        }
    };

    const downloadConversationAsPDF = () => {
        const doc = new jsPDF();
        let yOffset = 20; // Pour décaler chaque bulle verticalement

        doc.setFontSize(16);
        doc.text("Historique de la conversation :", 10, 10);

        conversationHistory.forEach((entry, index) => {
            const isUserMessage = entry.startsWith('Utilisateur');
            const bubbleColor = isUserMessage ? '#d1e7dd' : '#f8d7da'; // Vert clair pour l'utilisateur, rouge clair pour le bot
            const textColor = '#000'; // Noir pour le texte

            // Ajout d'un rectangle coloré pour la bulle
            doc.setFillColor(bubbleColor);
            doc.setTextColor(textColor);
            const textLines = doc.splitTextToSize(entry, 180); // Ajuster la taille du texte à une largeur de 180
            const bubbleHeight = textLines.length * 10 + 10; // Calculer la hauteur de la bulle en fonction de la quantité de texte
            doc.rect(10, yOffset, 180, bubbleHeight, 'F'); // Créer la bulle

            // Ajouter le texte de la conversation
            doc.text(textLines, 15, yOffset + 10);

            // Mettre à jour l'offset pour la prochaine bulle
            yOffset += bubbleHeight + 10; // Ajouter un espacement entre les bulles
        });

        doc.save("conversation.pdf");
    };


    return (
        <Modal show={show} onHide={onHide} centered dialogClassName="dark-modal modal-80w">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!hasStarted ? (
                    <div className="start-container text-center">
                        <Button variant="primary" onClick={handleStartExercise} style={{ fontSize: '1.4em' }}>
                            Commencer l'exercice
                        </Button>
                    </div>
                ) : (
                    <>
                        <div className="conversation-history">
                            {conversationHistory.length === 0 ? (
                                <Alert variant="info">Aucune conversation pour le moment. Commencez à parler ou tapez un
                                    message pour commencer.</Alert>
                            ) : (
                                conversationHistory.map((entry, index) => (
                                    <div key={index}
                                         className={`chat-bubble ${entry.startsWith('Utilisateur') ? 'user-bubble' : 'bot-bubble'}`}>
                                        <p>{entry}</p>
                                    </div>
                                ))
                            )}
                            <div ref={conversationEndRef}/>
                        </div>

                        <InputGroup className="mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Posez votre question..."
                                value={question || ''}
                                onChange={(e) => setQuestion(e.target.value)}
                                className="input-question"
                                disabled={loading}
                            />
                            <Button variant="primary" onClick={handleSendQuestion} className="ml-2" disabled={loading || !question.trim()}>
                                {loading ? <Spinner animation="border" size="sm" /> : "Envoyer"}
                            </Button>
                            <div style={{ margin: 10 }}>
                                <Button
                                    variant={isListening ? "danger" : "secondary"}
                                    onClick={isListening ? stopRecording : startRecording}
                                    className={isListening ? 'audio_stop' : 'audio_go'}
                                    disabled={loading && !isListening}
                                    style={{ borderRadius: '50%', padding: '1rem', margin: 10 }}
                                >
                                    <Microphone size={30} />
                                </Button>
                            </div>
                        </InputGroup>
                        <div className="d-flex justify-content-center">
                            <Button variant="success" onClick={speakText} disabled={!audioUrl || loading} className="d-flex align-items-center">
                                <Play size={20} className="mr-2" /> Lire la réponse
                            </Button>
                            <Button variant="warning" onClick={resetSpeaking} disabled={loading} className="ml-2 d-flex align-items-center">
                                <Stop size={20} className="mr-2" /> Arrêt
                            </Button>
                            <Button variant="info" onClick={downloadConversationAsPDF} className="ml-2 d-flex align-items-center">
                                Télécharger PDF
                            </Button>
                        </div>
                        {audioUrl && (
                            <div className="audio-container mt-3">
                                <audio ref={audioRef} onTimeUpdate={handleTimeUpdate} controls src={audioUrl}>
                                    Votre navigateur ne supporte pas l'élément audio.
                                </audio>
                            </div>
                        )}
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide} disabled={loading}>
                    Fermer
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ChatModal;

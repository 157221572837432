export const MenuItems = [
    {title: 'Admin', path: '/admin', icon: 'fas fa-fw fa-tachometer-alt', subMenu: []},
    {title: 'Dashboard', path: '/dashboard', icon: 'fas fa-fw fa-tachometer-alt', subMenu: []},
]

{/*
    { title: 'Mes Cours', path: '/cours', icon: 'fas fa-fw fa-table', subMenu: []},
    { title: 'Messagerie', path: '/messages', icon: 'fas fa-fw fa-table',subMenu: []},
    { title: 'Événements', path: '/events', icon: 'fas fa-fw fa-table',subMenu: []},
*/
}

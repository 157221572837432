const initialState = {
    list: [],
    pageNo: 1,
    pageSize: 10,
    totalCount: 0,
    searchKey: '',
    filters: {},
    message: ''
};

const coursesReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'SET_COURSES_LIST':
            return { ...state, ...action.payload };
        case 'SET_COURSES_MESSAGE':
            return { ...state, message: action.payload };
        default:
            return state;
    }
};

export default coursesReducer;

export { sidebarToggle, activateMenu, activateTopMenu } from "redux/slices/template";
export { setJWTToken, removeJWTToken } from 'redux/slices/auth';

export { setUsersList, resetUsersToInit, setUsersMessage } from 'redux/slices/users';


export const setCoursesList = (data) => ({
    type: 'SET_COURSES_LIST',
    payload: data
});

export const setCoursesMessage = (message) => ({
    type: 'SET_COURSES_MESSAGE',
    payload: message
});
